import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';
import {jwtDecode} from "jwt-decode";
import {Observable} from "rxjs";
import { map, filter } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import {SessionToken} from "../verify-email/verify-email.component";

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent implements OnInit {
  isCollapsed = true;
  faUser = faUser;
  faPowerOff = faPowerOff;
  owner$: Observable<boolean>;
  state: string;
  sessionData: SessionToken | null = null;


  constructor(
    public auth: AuthService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private doc: Document,
    private route: ActivatedRoute,
  ) {
    this.owner$ = this.auth.user$.pipe(
      filter(user => user !== null), // Filter out null values if needed
      map((user) => user['https://www.fenaco.com/poc/roles'].includes('Owner'))
    );
  }

  loginWithRedirect() {
    this.auth.loginWithRedirect();
  }

  signupWithRedirect() {
    this.auth.loginWithRedirect({
      authorizationParams: {
        screen_hint: 'signup',
      },
    });
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const sessionToken = params['session_token'];
      if (sessionToken) {
        this.state = params['state'];
        this.sessionData = this.decodeToken(sessionToken);
      }
      this.translate.defaultLang = this.sessionData?.locale || 'de';
      this.translate.use(this.sessionData?.locale || 'de');
    });
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }
}

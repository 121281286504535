<footer class="flex flex-col sm:flex-row bg-[#fafaf9] w-full p-8 sm:p-32">
  <div class="flex flex-col sm:items-start items-center sm:w-1/2 gap-4 sm:gap-8">
      <div class="flex w-full justify-start items-start">
          <h1 class="text-xl sm:text-4xl font-semibold text-[#009456] font-MyriadPro">{{ 'footer.lineOne' | translate }}</h1>
      </div>
      <div class="flex w-full justify-start items-start">
        <p class="text-sm sm:text-4xl font-light text-[#009456]">{{ 'footer.lineTwo' | translate }}</p>
      </div>
      <div class="flex w-full justify-start items-start">
        <button class="footer-read-more-button" (click)="goToLandingPage()">{{ 'footer.cta' | translate }}</button>
      </div>
  </div>
  <div class="flex flex-col sm:w-1/2 items-center gap-4 sm:gap-8 mt-8 sm:mt-0">
      <div class="flex flex-row justify-center sm:justify-end gap-6 sm:gap-14">
          <img src="../../assets/footer-icon-1.svg" width="193px" height="77px" alt="logo" class="w-16 sm:w-48" />
          <img src="../../assets/footer-icon-2.svg" width="193px" height="77px" alt="logo" class="w-16 sm:w-48" />
      </div>
      <div class="flex flex-row justify-center sm:justify-end gap-6 sm:gap-14">
          <img src="../../assets/footer-icon-3.svg" width="193px" height="77px" alt="logo" class="w-16 sm:w-48" />
          <img src="../../assets/footer-icon-4.svg" width="193px" height="77px" alt="logo" class="w-16 sm:w-48" />
      </div>
  </div>
</footer>

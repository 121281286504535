import {Component, Inject} from '@angular/core';
import { NgClass } from '@angular/common';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import {MAT_SNACK_BAR_DATA} from '@angular/material/snack-bar';

interface SnackbarData {
  messageTranslationId: string;
  textColor?: string;
}
/**
 * @title Basic snack-bar
 */
@Component({
  selector: 'app-snackbar',
  templateUrl: 'snackbar.component.html',
  styleUrls: ['snackbar.component.css'],
  standalone: true,
  imports: [MatFormFieldModule, MatInputModule, MatButtonModule, TranslateModule, NgClass],
})
export class SnackbarComponent {
  feedbackMessage = ''

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData, private _snackBar: MatSnackBar, public translate: TranslateService) {}

  openSnackBar(message: string, action: string) {
    this.feedbackMessage = message;
    this._snackBar.open(message, action);
  }
}

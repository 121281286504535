import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, User } from '@auth0/auth0-angular';

@Component({
  selector: 'app-change-email-flow',
  templateUrl: './change-email-flow.component.html',
  styleUrls: ['./change-email-flow.component.css'],
})
export class ChangeEmailFlowComponent implements OnInit {
  profile: User = null;

  constructor(public auth: AuthService, public translate: TranslateService) {}
  ngOnInit() {
    this.auth.user$.subscribe((profile) => (this.profile = profile));
  }
}

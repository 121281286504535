<div class="flex h-full flex-col items-center justify-center sm:justify-start bg-[#fafaf9] p-4">
  <div class="bg-white verifyEmailFeedback sm:mt-44 h-fit form-container">

    <!-- Second Row: Title -->
    <div class="flex text-center justify-center items-center">
      <img src="../../../assets/landi-logo.png" width="125" height="40" alt="Logo" />
    </div>

    <!-- Third Row: Bold Text -->
    <div class="mt-8 text-2xl leading-8">
      <p class="font-semibold">{{ 'verifyEmail.verifyTitle' | translate }}</p>
    </div>

    <div *ngIf="sessionData" class="mt-2 leading-5 text-base text-[#8E8E8A]">
      <p>{{ 'verifyEmail.verifyMessage' | translate }}</p>
    </div>

    <ng-template #showError>
      <p>{{ 'verifyEmail.errorMessage' | translate }}</p>
    </ng-template>

    <!-- Fifth Row: Link -->
    <div class="mt-4 flex justify-between">
      <a *ngIf="!sessionData.isResend" (click)="resend()" class="!text-[#009456] cursor-pointer font-semibold underline underline-offset-2">{{ 'verifyEmail.resendLabel' | translate }}</a>
      <a *ngIf="sessionData.isResend" class="!text-[#009456]">{{ 'verifyEmail.emailSent' | translate }}</a>
      <a (click)="cancel()" class="!text-[#009456] cursor-pointer">{{ 'verifyEmail.cancelLabel' | translate }}</a>
    </div>
  </div>
</div>

<!-- user-profile.component.html -->
<div
  class="bg-[#fafaf9] flex flex-col w-full min-h-screen shadow rounded-lg p-6"
>
  <div
    class="flex flex-col sm:flex-row w-full gap-y-1 sm:gap-8 sm:justify-start justify-end"
  >
    <div
      class="flex flex-col w-full justify-center items-center sm:w-4/12 sm:justify-start sm:items-end"
    >
      <ul class="flex flex-col gap-1 mt-10">
        <li
          class="flex flex-row items-center gap-2"
          (click)="activeMenu('menuItem1')"
        >
          <a
            id="menuItem1"
            [ngClass]="{ activeItem: activeMenuItem === 'menuItem1' }"
            class="flex flex-row items-center gap-4 h-8 hover:bg-[#009456] p-2 rounded-3xl"
          >
            <mat-icon class="!w-7">account_circle</mat-icon>

            <p
              [ngClass]="{ activeItem: activeMenuItem === 'menuItem1' }"
              class="text-sm text-gray-800 mb-4 mt-4 font-bold"
            >
              {{ 'userProfile.sideMenu.menuItem1' | translate }}
            </p>
          </a>
        </li>
        <li
          class="flex flex-row items-center gap-2"
          (click)="activeMenu('menuItem2')"
        >
          <a
            id="menuItem2"
            [ngClass]="{ activeItem: activeMenuItem === 'menuItem2' }"
            class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9] p-2 rounded-3xl"
            [routerLink]="['/']"
          >
            <mat-icon class="!w-7">lock</mat-icon>
            <p
              class="text-sm text-gray-800 mb-4 mt-4 font-bold"
              [ngClass]="{ activeItem: activeMenuItem === 'menuItem2' }"
            >
              {{ 'userProfile.sideMenu.menuItem2' | translate }}
            </p>
          </a>
        </li>

        <hr />
        <li
          class="flex flex-row items-center gap-2"
          (click)="activeMenu('menuItem3')"
        >
          <a
            id="menuItem"
            [ngClass]="{ activeItem: activeMenuItem === 'menuItem3' }"
            class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9] p-2 rounded-3xl"
          >
            <mat-icon class="!w-7">info</mat-icon>
            <p
              class="text-sm text-gray-800 mb-4 mt-4 font-bold"
              [ngClass]="{ activeItem: activeMenuItem === 'menuItem3' }"
            >
              {{ 'userProfile.sideMenu.menuItem7' | translate }}
            </p>
          </a>
        </li>
      </ul>
    </div>
    <div
      class="flex flex-col gap-3 w-full justify-center items-center sm:w-8/12 sm:justify-start sm:items-start"
    >
      <form
        class="bg-transparent flex flex-col gap-3"
        #editProfileForm="ngForm"
        (ngSubmit)="saveChanges(editProfileForm)"
      >
        <div
          class="flex flex-col w-full sm:w-80 h-fit bg-white p-6 gap-y-1 sm:gap-4 rounded-sm border-solid border-[#dddddd] border-1"
        >
          <div class="flex flex-col gap-0">
            <h1 class="text-black text-xl font-semibold">
              {{ 'editProfile.formTitle' | translate }}
            </h1>
            <p class="text-gray-500 text-sm font-normal">
              {{ 'editProfile.formDescription' | translate }}
            </p>
          </div>
          <div class="flex flex-col gap-1">
            <div class="flex flex-col gap-y-1 sm:gap-4">
              <div class="flex flex-col gap-1 w-full">
                <p class="text-gray-500 text-sm font-normal">Anrede</p>
                <select
                  class="text-left w-full text-sm p-2 border-solid border-[#E2E8F0] inputBorder font-normal text-black"
                  id="salutation"
                  required="true"
                  [(ngModel)]="formData.salutation"
                  name="salutation"
                >
                  <option disabled value="">
                    {{ 'editProfile.selectSalutation' | translate }}
                  </option>
                  <option value="mr">
                    {{ 'editProfile.salutationOptions.mr' | translate }}
                  </option>
                  <option value="ms">
                    {{ 'editProfile.salutationOptions.ms' | translate }}
                  </option>
                  <option value="other">
                    {{ 'editProfile.salutationOptions.other' | translate }}
                  </option>
                </select>
              </div>
              <div class="flex flex-col gap-1 w-full">
                <p class="text-gray-500 text-sm font-normal">
                  {{ 'editProfile.firstName' | translate }}
                </p>
                <input
                  class="text-left w-full text-sm p-2 border-solid border-[#E2E8F0] inputBorder font-normal text-black"
                  id="firstName"
                  required="true"
                  type="text"
                  placeholder="First Name"
                  [(ngModel)]="formData.firstName"
                  name="firstName"
                  [(ngModel)]="formData.firstName"
                />
              </div>
              <div class="flex flex-col gap-1 w-full">
                <p class="text-gray-500 text-sm font-normal">
                  {{ 'editProfile.lastName' | translate }}
                </p>
                <input
                  class="text-left w-full text-sm p-2 border-solid border-[#E2E8F0] inputBorder font-normal text-black"
                  id="lastName"
                  required="true"
                  type="text"
                  placeholder="Last Name"
                  [(ngModel)]="formData.lastName"
                  name="lastName"
                />
              </div>
            </div>
            <div class="flex flex-col gap-1 w-full pt-10 pb-10">
              <p class="text-gray-500 text-sm font-normal">
                {{ 'editProfile.preferredLanguage' | translate }}
              </p>
              <select
                class="text-left w-full text-sm p-2 border-solid border-[#E2E8F0] inputBorder font-normal text-black"
                id="locale"
                required="true"
                name="locale"
                [(ngModel)]="formData.locale"
              >
                <option disabled value="">
                  {{ 'editProfile.selectLanguage' | translate }}
                </option>
                <option value="de">
                  {{ 'editProfile.languageOptions.de' | translate }}
                </option>
                <option value="fr">
                  {{ 'editProfile.languageOptions.fr' | translate }}
                </option>
              </select>
            </div>
            <div class="flex flex-col gap-1">
              <div class="flex flex-col gap-4">
                <div class="flex flex-col gap-1 w-full">
                  <p class="text-gray-500 text-sm font-normal">
                    {{
                      'editProfile.changeEmailAndPasswordSection' | translate
                    }}
                  </p>
                  <div class="flex flex-row gap-2">
                    <mat-icon class="formIcons">mail_outline</mat-icon>

                    <div
                      class="text-left w-full text-sm p-2 font-normal text-black disabled:bg-white disabled:!text-black"
                    >
                      {{
                        user?.email.length > 20
                          ? user?.email.substring(0, 20) + '...'
                          : user?.email
                      }}
                    </div>

                    <div
                      class="flex flex-row gap-1 w-14 justify-center items-center"
                      href="#"
                    >
                      <a
                        class="text-[#334155] text-sm font-normal"
                        [routerLink]="['/change-email']"
                      >
                        {{ 'editProfile.changeLinkCaption' | translate }}
                      </a>
                      <mat-icon class="linkIcons">open_in_new</mat-icon>
                    </div>
                  </div>
                  <div class="flex flex-row gap-2">
                    <mat-icon class="formIcons">lock</mat-icon>

                    <div
                      class="text-left w-full text-base p-2 font-normal text-black leading-7"
                    >
                      ••••••••
                    </div>

                    <div
                      class="flex flex-row gap-1 w-14 justify-center items-center"
                      href="#"
                    >
                      <a
                        class="text-[#334155] text-sm font-normal"
                        [routerLink]="['/change-password']"
                      >
                        {{ 'editProfile.changeLinkCaption' | translate }}
                      </a>
                      <mat-icon class="linkIcons">open_in_new</mat-icon>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="flex flex-row w-full gap-2">
          <button
            class="flex flex-row text-center justify-center items-center w-24 buttonStyle text-base text-[#334155] bg-[#fff] rounded-sm border-2 border-[#DDDDDD]"
            (click)="resetForm()"
            type="button"
          >
            {{ 'editProfile.backCta' | translate }}
          </button>
          <button
            [ngClass]="{
              'bg-[#727573]': isLoading === true,
              'bg-[#019640]': isLoading === false,
              'border-[#009456]': isLoading === false,
              'border-[#727573]': isLoading === true
            }"
            [disabled]="isLoading"
            class="flex flex-row text-center justify-center items-center w-24 buttonStyle text-base text-[#ffffff] rounded-sm border-2 border-[#009456]"
            type="submit"
          >
            {{ 'editProfile.cta' | translate }}
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

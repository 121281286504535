import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import {ChangePasswordComponent} from "./pages/change-password/change-password.component";
import { LoginComponent } from "./pages/login/login.component";

import { AboutFenacoIdComponent } from './pages/about-fenaco-id/about-fenaco-id.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { EmailVerificationComponent } from './pages/email-verification/email-verification.component';
import { ProgressiveProfilingComponent } from "./pages/progressive-profiling/progressive-profiling.component";
import { ErrorComponent } from './pages/error/error.component';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { EditProfileComponent } from './pages/edit-profile/edit-profile.component';
import { ChangeEmailFlowComponent } from './pages/change-email-flow/change-email-flow.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const routes: Routes = [
  {
    path: 'email-verification',
    component: EmailVerificationComponent,
    // canActivate: [AuthGuard],
  },
  {
    path: 'company',
    component: ProgressiveProfilingComponent,
    providers: [TranslateModule]
    // canActivate: [AuthGuard],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: '',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change-password',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-profile',
    component: EditProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'about-fenaco-id',
    component: AboutFenacoIdComponent,
  },
  {
    path: 'change-email-flow',
    component: ChangeEmailFlowComponent,
    canActivate: [AuthGuard],
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {}), FormsModule, ReactiveFormsModule, TranslateModule.forRoot({
    defaultLanguage: 'de',
    loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
    }
}),],
  exports: [RouterModule, ReactiveFormsModule, FormsModule, TranslateModule],
})
export class AppRoutingModule {}

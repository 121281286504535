import { Component, Input, OnInit } from '@angular/core';
import { User } from "@auth0/auth0-angular";
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {DataService} from "../../data.service";
import { environment } from '../../../environments/environment';

type UserProfile = {
  firstName: string;
  lastName: string;
  locale: string;
  salutation: string;
}

@Component({
  selector: 'app-profile-content',
  templateUrl: './profile-content.component.html',
  styleUrls: ['./profile-content.component.css'],
})
export class ProfileContentComponent extends Component implements OnInit {
  @Input() user: User; // The type should match the expected data type
  userProfile: UserProfile = null;
  activeMenuItem = 'menuItem1'
  editProfileForm: FormGroup;
  isLoading = false;
  formData = {
    salutation: '',
    firstName: '',
    lastName: '',
    locale: '',
  };

  constructor(
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dataService: DataService
  ) {
    super({});
    this.translate = translate;
  }

  saveChanges(form: NgForm) {
    this.isLoading = true;

    // Handle saving changes
    if (form.valid) {
      // Proceed with saving changes
      // send post request to update profile
      const formData = form.value;
      this.dataService.postData(environment.updatePorfileEndpoint, formData).subscribe({
        next: (response) => {
          this.translate.get("editProfile.feedbacks.profileUpdated").subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['success-snackbar'],
            });
          });
          this.isLoading = false;
        },
        error: (error) => {
          this.translate.get("editProfile.feedbacks.profileUpdateFailed").subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['error-snackbar'],
            });
          });
          this.isLoading = false;
        }
      });
    } else {
      if (
        form.controls.firstName.errors?.required &&
        form.controls.lastName.errors?.required
      ) {
        return this.translate.get("errors.firstName").subscribe((translatedFeedback: string) => {
          this._snackBar.open(translatedFeedback, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
      }
      if (form.controls.lastName.errors?.required) {
        return this.translate.get("errors.lastName").subscribe((translatedFeedback: string) => {
          this._snackBar.open(translatedFeedback, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
      }
      if (form.controls.firstName.errors?.required) {
        return this.translate.get("errors.firstName").subscribe((translatedFeedback: string) => {
          this._snackBar.open(translatedFeedback, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
      }
      this.isLoading = false;
    }
    this.translate.setDefaultLang(this.formData.locale);
    this.translate.use(this.formData.locale);
  }

  activeMenu(selectedItem: string){
    this.activeMenuItem = selectedItem;
    event.stopPropagation();
  }
  ngOnInit() {
    this.userProfile = this.user['com.fenaco.id/profile'];
    if (this.userProfile) {
      this.formData = { ...this.userProfile };
      this.translate.use(this.formData.locale);
    }
  }

  resetForm() {
    this.formData = { ...this.userProfile };
  }
}

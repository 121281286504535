<div
  class="flex h-full flex-col items-center justify-center sm:justify-start bg-[#fafaf9] p-4"
>
  <form
    class="bg-white changePasswordForm sm:mt-44 h-fit"
    [formGroup]="progressiveProfilingForm"
    (ngSubmit)="submitForm()"
  >
    <div class="flex justify-end items-end p-4"></div>

    <div
      class="flex flex-col max-w-md mx-auto pl-8 pr-8 pb-8 bg-white rounded-lg shadow-md gap-2"
    >
      <div class="flex text-center justify-center items-center pt-3 pb-3">
        <img
          src="https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200"
          width="198px"
          height="46px"
          alt="Fenaco"
        />
      </div>

      <div class="mt-2 text-2xl leading-8">
        <p class="font-bold">
          {{ 'progressiveProfiling.step1.title' | translate }}
          <span>{{ this.step }}/2</span>
        </p>
      </div>

      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        {{ 'progressiveProfiling.step1.description' | translate }}
      </div>

      <div
        class="relative flex"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <select
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pr-10 appearance-none"
          name="landi"
          formControlName="landi"
          (change)="onLandiSelect($event.target.value)"
        >
          <option disabled value="">
            {{ 'progressiveProfiling.selectLandi' | translate }}
          </option>
          <ng-container *ngFor="let landi of sessionData.landis">
            <option [value]="landi.id">{{ landi.name }}</option>
          </ng-container>
        </select>
        <div
          class="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none"
        >
          <svg
            class="h-5 w-5 text-[#009036]"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
      </div>
      <div
        class="flex flex-row w-full justify-start items-start"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <p class="mt-2 leading-5 text-base font-light text-[#8E8E8A]">
          {{ 'progressiveProfiling.step1.whatIsLandi' | translate }}
        </p>
        <div class="has-tooltip">
          <mat-icon class="info-landi-icon has-tooltip">info_outline</mat-icon>
          <span
            class="tooltip rounded shadow-lg p-4 bg-primary-light w-fit relative -mt-[80px] -ml-[190px]"
            >{{
              'progressiveProfiling.step1.whatIsLandiInfo' | translate
            }}</span
          >
        </div>
      </div>

      <div
        class="mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 2, flex: step === 1 }"
      >
        <button
          class="flex flex-row items-center justify-center w-full h-12 p-1 text-base text-[#ffffff] bg-[#019640] rounded-sm border-2 border-[#009456] disabled:opacity-50"
          [disabled]="!landiSelected"
          (click)="nextStep()"
        >
          {{ 'progressiveProfiling.cta' | translate }}
        </button>
      </div>
      <!-- Start of second Step -->
      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        {{
          'progressiveProfiling.step2.description'
            | translate : { landi_name: landiName }
        }}
      </div>

      <div
        class="mt-2 text-left flex flex-col"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pr-10 appearance-none"
          id="customerNr"
          required="true"
          type="text"
          placeholder="{{
            'progressiveProfiling.step2.customerNr.placeholder' | translate
          }}"
          name="customerNr"
          formControlName="customerNr"
        />
      </div>

      <div
        class="flex flex-row w-full justify-start items-start"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <p class="mt-2 leading-5 text-base font-light text-[#8E8E8A]">
          {{ 'progressiveProfiling.step2.whereToFind' | translate }}
        </p>
        <div class="has-tooltip">
          <mat-icon class="info-landi-icon has-tooltip">info_outline</mat-icon>
          <span
            class="tooltip rounded shadow-lg p-4 bg-primary-light w-fit relative -mt-[80px] -ml-[190px]"
            >{{
              'progressiveProfiling.step2.whereToFindInfo' | translate
            }}</span
          >
        </div>
      </div>

      <div
        class="flex flex-row gap-2 mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step === 1, flex: step === 2 }"
      >
        <button
          class="flex flex-row items-center justify-center w-full h-12 p-1 text-base text-[#86847F] bg-[#fff] rounded-sm border-2 border-[#DDDDDD] disabled:opacity-50"
          (click)="prevStep()"
        >
          {{ 'progressiveProfiling.step2.backCta' | translate }}
        </button>
        <button
          type="submit"
          class="flex flex-row items-center justify-center w-full h-12 p-1 text-base text-[#ffffff] bg-[#019640] rounded-sm border-2 border-[#009456] disabled:opacity-50"
          [disabled]="!progressiveProfilingForm.valid && isSubmitted"
        >
          {{ 'progressiveProfiling.step2.cta' | translate }}
        </button>
      </div>
      <a
        (click)="cancel()"
        class="text-base text-[#8E8E8A] cursor-pointer self-end mt-4"
        >{{ 'progressiveProfiling.cancelLabel' | translate }}</a
      >
    </div>
  </form>
</div>

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {ActivatedRoute, Router, NavigationEnd} from "@angular/router";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  isLandingPage = false;

  constructor(translate: TranslateService, private route: ActivatedRoute,private router: Router ) {
    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('de');

    translate.addLangs(['de', 'fr']);

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    translate.use('de');
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd) {
         if(this.router.url === '/about-fenaco-id') {
           this.isLandingPage = true;
         }
      }
    });  }
}

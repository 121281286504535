import { Component, OnInit, HostListener, Renderer2, ElementRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-about-fenaco-id',
  templateUrl: './about-fenaco-id.component.html',
  styleUrls: ['./about-fenaco-id.component.css'],
})
export class AboutFenacoIdComponent implements OnInit  {
  activeLang = 'de';
  constructor(private renderer: Renderer2, private elementRef: ElementRef, public translate: TranslateService) { }

  scrollTop() {
    window.scrollTo(0, 0);
  }

  ngOnInit() {
    // Initially check if the screen size is small and add the class if scrolled
    this.checkScreenSizeAndScroll();
  }

  // onWindowScroll() {
  //   const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
  //   const element = document.getElementById('#top');
  //   console.log("scrollPosition: ", scrollPosition)
  //   console.log("element: ", element  );
  //   if (element) {
  //     if (scrollPosition > 0) {
  //       element.classList.add('scrolled-down'); // Add your new class here
  //     } else {
  //       element.classList.remove('scrolled-down'); // Remove the class if user scrolls to top
  //     }
  //   }
  // }

  @HostListener('window:scroll', ['$event'])
  checkScreenSizeAndScroll() {
    // Get the width of the window
    const screenWidth = window.innerWidth;
    // Check if the width is less than or equal to 768px (which typically corresponds to mobile screens)
    if (screenWidth <= 768) {
      const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
      const element = document.getElementById('#top');

      // Add or remove the class based on scroll position
      if (element) {
        if (scrollPosition > 0) {
          this.renderer.addClass(element, 'scrolled-down');
        } else {
          this.renderer.removeClass(element, 'scrolled-down');
        }
      }
    }
  }

  changeColor(lang: string) {
    console.log("lang: ", lang)
    this.translate.use(lang);
    this.activeLang = lang;
  }
}

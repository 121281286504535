import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
})
export class LoginComponent implements OnInit {
  constructor(public auth: AuthService, private route: ActivatedRoute) {}
  ngOnInit() {
    const organizationId = this.route.snapshot.queryParams["organization"];
    const invitationToken = this.route.snapshot.queryParams["invitation"];


    this.auth.loginWithRedirect({
      authorizationParams: {
        organization: organizationId,
        invitation: invitationToken,
      },
    });
  }

}

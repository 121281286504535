import { Component, Input, OnInit } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormBuilder, FormGroup, Validators, NgForm } from '@angular/forms';
import {DataService} from "../../data.service";
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { environment } from '../../../environments/environment';

type UserProfile = {
  firstName: string;
  lastName: string;
  locale: string;
  salutation: string;
};

@Component({
  selector: 'app-edit-profile-content',
  templateUrl: './edit-profile-content.component.html',
  styleUrls: ['./edit-profile-content.component.css'],
})
export class EditProfileContentComponent extends Component implements OnInit {
  @Input() user: User; // The type should match the expected data type
  userProfile: UserProfile = null;
  activeMenuItem = 'menuItem1';
  editProfileForm: FormGroup;
  durationInSeconds = 5;

  formData = {
    salutation: '',
    firstName: '',
    lastName: '',
    locale: '',
  };

  constructor(
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private formBuilder: FormBuilder,
    private dataService: DataService
  ) {
    super({});
    this.translate = translate;
  }

  saveChanges(form: NgForm) {
    // Handle saving changes
    if (form.valid) {
      // Proceed with saving changes
      // send post request to update profile
      const formData = form.value;
      this.dataService.postData(environment.updatePorfileEndpoint, formData).subscribe({
        next: (response) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            data: { messageTranslationId: 'success.profileUpdated' },
            panelClass: ['success-snackbar'],
          });
        },
        error: (error) => {
          this._snackBar.openFromComponent(SnackbarComponent, {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            data: { messageTranslationId: 'success.profileUpdated' },
            panelClass: ['success-snackbar'],
          });
        }
      });
    } else {
      if (
        form.controls.firstName.errors?.required &&
        form.controls.lastName.errors?.required
      ) {
        return this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          verticalPosition: 'top',
          politeness: 'assertive',
          data: { messageTranslationId: 'errors.general' },
          panelClass: ['error-snackbar'],
        });
      }
      if (form.controls.lastName.errors?.required) {
        return this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          verticalPosition: 'top',
          politeness: 'assertive',
          data: { messageTranslationId: 'errors.lastName' },
          panelClass: ['error-snackbar'],
        });
      }
      if (form.controls.firstName.errors?.required) {
        return this._snackBar.openFromComponent(SnackbarComponent, {
          duration: 3000,
          verticalPosition: 'top',
          politeness: 'assertive',
          data: { messageTranslationId: 'errors.firstName' },
          panelClass: ['error-snackbar'],
        });
      }
    }
    this.translate.setDefaultLang(this.formData.locale);
    this.translate.use(this.formData.locale);
  }

  activeMenu(selectedItem: string) {
    this.activeMenuItem = selectedItem;
    event.stopPropagation();
  }

  ngOnInit() {
    this.userProfile = this.user['com.fenaco.id/profile'];
    if (this.userProfile) {
      this.formData = { ...this.userProfile };
      this.translate.use(this.formData.locale);
    }
  }
}

<div
  class="flex h-full flex-col items-center justify-center sm:justify-start bg-[#fafaf9] p-4"
>
  <form class="bg-white changeEmailForm sm:mt-44 h-fit"   [formGroup]="changeEmailForm">

    <div
      class="flex flex-col h-fit max-w-md mx-auto formContent bg-white rounded-lg shadow-md gap-5 pb-11"
    >
      <div class="flex text-center justify-center items-center pt-3 pb-3">
        <img
          src="../../assets/footer-icon-3.svg"
          width="124px"
          height="39px"
          alt="Fenaco"
        />
      </div>
      <div class="flex text-left">
        <p class="flex text-2xl font-semibold">
          {{ 'changeEmailFlow.title' | translate }}
        </p>
      </div>
      <div class="mt-2 leading-5 text-base font-light text-[#8E8E8A]" [ngClass]="{ hidden: step !== 1 , flex: step === 1 }">
          {{ 'changeEmailFlow.description' | translate }}
      </div>
      <div class="mt-2 leading-5 text-base font-light text-[#8E8E8A]" [ngClass]="{ hidden: step !== 2 , flex: step === 2 }">
        {{ 'changeEmailFlow.codeDescription' | translate }}
      </div>
      <div class="mt-2 leading-5 text-base font-light text-[#8E8E8A] flex-col" [ngClass]="{ hidden: step !== 3 , flex: step === 3 }">
        {{ 'changeEmailFlow.emailChangedFeedback' | translate }}
        <a (click)="goToLogin()" class="resendLink mt-2">{{ 'changeEmailFlow.goToLogin' | translate }}</a>
      </div>
      <fieldset [disabled]="step !== 1" [ngClass]="{ hidden: step !== 1 , flex: step === 1 }">

      <div class="relative w-full">
        <mat-icon
          class="absolute !overflow-visible inset-y-0 left-0 flex items-center pl-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
        >
          email
        </mat-icon>
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pl-10 pr-14 appearance-none"
          [ngClass]="{'bg-[#4e4e4e]': step !== 1}"
          id="currentEmail"
          required="true"
          type="email"
          placeholder="{{ 'changeEmailFlow.placeholder' | translate }}"
          formControlName="currentEmail"
          name="currentEmail"
          [email]="true"
        />
      </div>
    </fieldset>

    <fieldset [disabled]="step !== 2" [ngClass]="{ hidden: step !== 2, flex: step === 2 }">

      <div class="relative w-full">
        <mat-icon
          class="absolute !overflow-visible inset-y-0 left-0 flex items-center pl-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
        >
          password
        </mat-icon>
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pl-10 pr-14 appearance-none"
          id="code"
          required="true"
          type="text"
          placeholder="{{ 'changeEmailFlow.code' | translate }}"
          formControlName="code"
          name="code"
        />
      </div>
    </fieldset>
      <div
        class="flex flex-col gap-2 mt-2 mb-2 leading-5"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <button
        style="height: 50px"
        [ngClass]="{ 'bg-[#727573]': isLoading === true , 'bg-[#019640]': isLoading === false, 'border-[#009456]': isLoading === false, 'border-[#727573]': isLoading === true }"
        [disabled]="isLoading"
        (click)="verificationCodeStep()"
        class="flex flex-row items-center justify-center w-full p-1 text-base text-[#ffffff] rounded-sm border-2 disabled:opacity-50"        >
          {{ 'changeEmailFlow.cta' | translate }}
        </button>
      </div>
      <div
      class="flex flex-col gap-2 mt-2 mb-2 leading-5"
      [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
    >
      <button
      style="height: 50px"
      [ngClass]="{ 'bg-[#727573]': isLoading === true , 'bg-[#019640]': isLoading === false, 'border-[#009456]': isLoading === false, 'border-[#727573]': isLoading === true }"
      [disabled]="isLoading"
      (click)="confirmChangeEmail()"
      class="flex flex-row items-center justify-center w-full p-1 text-base text-[#ffffff] rounded-sm border-2 disabled:opacity-50"        >
        {{ 'changeEmailFlow.confirmCta' | translate }}
      </button>
    </div>
    <div
        class="flex flex-col gap-2 mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
      >
      <a (click)="resendCode()" class="resendLink">{{ 'changeEmailFlow.resendCode' | translate }}</a>
      </div>
    </div>
  </form>
</div>

import { Component } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { jwtDecode } from "jwt-decode";
import { TranslateService } from '@ngx-translate/core';

export type SessionToken = {
  continue_uri: string
  locale: string
  email: string
  isResend: boolean
  landis: { id: string, name: string }[]
  exp: number
  iat:number
  ip: string
  iss: string
  sub: string
  error?: string
  backUrl?: string
  clientName?: string
}

@Component({
  selector: 'app-verify-email',
  templateUrl: './verify-email.component.html',
  styleUrls: ['./verify-email.component.css']
})
export class VerifyEmailComponent {
  sessionData: SessionToken | null = null;
  state: string | null = null;


  constructor(private route: ActivatedRoute, private router: Router, public translate: TranslateService) {}
  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      const sessionToken = params['session_token']; // Replace 'myParam' with your parameter name
      if (!sessionToken) {
       this.router.navigate(['/error']);
      }

      this.state = params['state'];
      if (!this.state) {
        this.router.navigate(['/error']);
      }

      this.sessionData = this.decodeToken(sessionToken);
      this.translate.use(this.sessionData.locale || 'de');
    });
  }

  decodeToken(token: string) {
    try {
      return jwtDecode<SessionToken>(token);
    } catch (Error) {
      return null;
    }
  }

  continue() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}`;
  }

  resend() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}&resend=true`;
  }

  cancel() {
    window.location.href = `${this.sessionData?.continue_uri}?state=${this.state}&cancel=true`;
  }

}

import { Component, OnInit, Input } from '@angular/core';
import {AuthService, User} from '@auth0/auth0-angular';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-footer-content',
  templateUrl: './footer-content.component.html',
  styleUrls: ['./footer-content.component.css']
})
export class FooterContentComponent implements OnInit {
    constructor(public auth: AuthService, private route: ActivatedRoute, private router: Router) {}


  ngOnInit() {
  }

  goToLandingPage() {
    this.router.navigate(['/about-fenaco-id']);
  }
}

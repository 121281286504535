import { Component, OnInit } from '@angular/core';
import {AuthService, User} from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css'],
})
export class ChangePasswordComponent implements OnInit {
  profile: User = null;

  constructor(public auth: AuthService, public translate: TranslateService) {}

  ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => (this.profile = profile)
    );
  }
}

<!-- edit-user-profile.component.html -->
<div class="bg-[#fafaf9] flex flex-col w-full min-h-screen shadow rounded-lg p-6 sm:justify-start sm:items-start">
  <div class="flex flex-col sm:flex-row w-full gap-8 sm:justify-start justify-end">
    <div
      class="flex flex-col w-full justify-center items-center sm:w-4/12 sm:justify-start sm:items-end"
    >
      <ul class="flex flex-col gap-1 mt-10">
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem1')">
          <a href="#" id="menuItem1" [ngClass]="{'activeItem': activeMenuItem === 'menuItem1'}" class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>account_circle</mat-icon>

            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem1' | translate }}
            </p>
          </a>
        </li>
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem2')">
          <a href="#" id="menuItem2" [ngClass]="{'activeItem': activeMenuItem === 'menuItem2'}" class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9] p-2 rounded-3xl">
            <mat-icon>badge</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem2' | translate }}
            </p>
          </a>
        </li>
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem3')">
          <a href="#" id="menuItem3" [ngClass]="{'activeItem': activeMenuItem === 'menuItem3'}"  class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>toggle_off</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem3' | translate }}
            </p>
          </a>
        </li>
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem4')">
          <a href="#" id="menuItem3" [ngClass]="{'activeItem': activeMenuItem === 'menuItem4'}"  class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>lock</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem4' | translate }}
            </p>
          </a>
        </li>
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem5')">
          <a href="#" id="menuItem4" [ngClass]="{'activeItem': activeMenuItem === 'menuItem5'}"  class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>group</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem5' | translate }}
            </p>
          </a>
        </li>
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem6')">
          <a href="#" id="menuItem" [ngClass]="{'activeItem': activeMenuItem === 'menuItem6'}"  class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>credit_card</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem6' | translate }}
            </p>
          </a>
        </li>
        <hr />
        <li class="flex flex-row items-center gap-2" (click)="activeMenu('menuItem7')">
          <a href="#" id="menuItem" [ngClass]="{'activeItem': activeMenuItem === 'menuItem7'}"  class="flex flex-row items-center gap-2 h-8 hover:bg-[#e9e9e9]  p-2 rounded-3xl">
            <mat-icon>info</mat-icon>
            <p class="text-sm text-gray-800 mb-4 mt-4 font-bold">
              {{ 'userProfile.sideMenu.menuItem7' | translate }}
            </p>
          </a>
        </li>

      </ul>
    </div>
    <div
      class="flex flex-col w-full justify-center items-center sm:w-8/12 sm:justify-start sm:items-start"
    >
      <div class="flex flex-col">
        <p class="text-lg font-semibold">
          {{ 'editProfile.title' | translate }}
        </p>
      </div>
      <div class="max-w-md mx-auto sm:mx-0 sm:max-w-none mt-8 w-full sm:w-2/4">
        <form class="bg-transparent shadow-md rounded px-4 py-6" #editProfileForm="ngForm" (ngSubmit)="saveChanges(editProfileForm)">
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="salutation">
              {{ 'editProfile.salutation' | translate }}
            </label>
            <select
            class="shadow appearance-none text-[#8E8E8E] bg-white border-2 border-[#e9e9e9] rounded w-full py-2 px-3 leading-tight focus:outline-none focus:shadow-outline"
            id="salutation" required="true" [(ngModel)]="formData.salutation" name="salutation">
            <option disabled value="">{{ 'editProfile.selectSalutation' | translate }}</option>
            <option value="mr">{{ 'editProfile.salutationOptions.mr' | translate }}</option>
            <option value="ms">{{ 'editProfile.salutationOptions.ms' | translate }}</option>
              <option value="other">{{ 'editProfile.salutationOptions.other' | translate }}</option>
          </select>

          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="firstName">
              {{ 'editProfile.firstName' | translate }}
            </label>
           <!-- text-left text-sm p-2 text-[#8E8E8E] pr-4 bg-white border-2 border-[#e9e9e9] focus:outline-none w-full -->
            <input
              class="shadow appearance-none rounded w-full py-2 px-3 text-[#8E8E8E] bg-white border-2 border-[#e9e9e9] leading-tight focus:outline-none focus:shadow-outline"
              id="firstName" required="true" type="text" placeholder="First Name" [(ngModel)]="formData.firstName" name="firstName">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="lastName">
              {{ 'editProfile.lastName' | translate }}
            </label>
            <input
            class="shadow appearance-none rounded w-full py-2 px-3 text-[#8E8E8E] bg-white border-2 border-[#e9e9e9] leading-tight focus:outline-none focus:shadow-outline"
            id="lastName" required="true" type="text" placeholder="Last Name" [(ngModel)]="formData.lastName" name="lastName">
          </div>
          <div class="mb-4">
            <label class="block text-gray-700 text-sm font-bold mb-2" for="locale">
              {{ 'editProfile.preferredLanguage' | translate }}
            </label>
            <select
            class="shadow appearance-none rounded w-full py-2 px-3 text-[#8E8E8E] bg-white border-2 border-[#e9e9e9] leading-tight focus:outline-none focus:shadow-outline"
            id="locale" required="true" [(ngModel)]="formData.locale" name="locale">
            <option disabled value="">{{ 'editProfile.selectLanguage' | translate }}</option>
            <option value="de">{{ 'editProfile.languageOptions.de' | translate }}</option>
            <option value="fr">{{ 'editProfile.languageOptions.fr' | translate }}</option>
          </select>
          </div>
          <div class="flex items-center justify-center md:justify-end">
            <button
              class="text-[#ffffff] bg-[#019640] rounded-sm font-bold py-2 px-4 focus:outline-none focus:shadow-outline"
              type="submit">
              {{ 'editProfile.cta' | translate }}
            </button>
          </div>
        </form>
      </div>

    </div>
  </div>
</div>

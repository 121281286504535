<div class="flex flex-row w-full h-[92px] bg-[#ffffff] border-b-2 border-b-[#e9e9e9] justify-between gap-y-1 sm:gap-4">
  <div class="flex w-fit p-2 text-center justify-center items-center">
    <div *ngIf="(this.sessionData && this.sessionData?.backUrl)">
      <a class="navbar-back ml-4" href="{{sessionData.backUrl}}">
        <img src="../../assets/arrow-back.png" width="50px" alt="&laquo;" />
        zurück zu {{sessionData.clientName}}
      </a>
    </div>
  </div>
  <div class="flex items-center justify-end">
  <div class="flex w-45 p-2 text-center justify-center items-center" *ngIf="(auth.isAuthenticated$ | async) === true"
  >
    <button
      class="flex flex-row text-center justify-center items-center w-fit p-2 h-10 buttonStyle text-base text-[#334155] bg-[#fff] rounded-sm border-2 border-[#DDDDDD]"
      style="min-width: unset"
      id="qsLogoutBtn"
      (click)="logout()"
    >
      <span class="flex">{{ 'navbar.logout' | translate }}</span> <mat-icon class="linkIcons">logout</mat-icon>

    </button>
  </div>
  <!-- <div class="flex w-fit p-2 text-center justify-center items-center" *ngIf="(auth.isAuthenticated$ | async) === false"
  >
    <button
      id="qsLoginBtn"
      class="flex flex-row text-center justify-center items-center w-40 h-10 buttonStyle text-base text-[#334155] bg-[#fff] rounded-sm border-2 border-[#DDDDDD]"
      (click)="loginWithRedirect()"
    >
    <span class="flex text-[#334155]">Login</span> <mat-icon class="linkIcons">login</mat-icon>
    </button>
  </div> -->
  <div class="flex w-45 p-2 items-center justify-center">
    <img src="../../assets/fenaco-logo.png" alt="Fenaco logo" class="h-[42px]" />
  </div>
  </div>
</div>


export const environment = {
  production: false,
  changePasswordEndpoint: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api-uat/change-password', // Update to staging URLs
  updatePorfileEndpoint: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api-uat/update-profile', // Update to staging URLs
  changeEmailEndpoint: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api-uat/change-email', // Update to staging URLs
  changeEmailConfirmEndpoint: 'https://europe-west6-prj-fenit-2727455369-ciamn.cloudfunctions.net/api-uat/change-email-confirm',
  auth: {
    domain:  "id-test.fenaco.com",
    clientId: "ZoHp4PTXCQ5dS1wsFrjM3mbs4aZPRRoS",

    authorizationParams: {
      audience: "https://fenaco.com/default",
      redirect_uri: 'https://profile-test.fenaco.com'
    },
    errorPath: "/error",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.

<div
  class="flex h-full flex-col items-center justify-center sm:justify-start bg-[#fafaf9] p-4"
>
  <form
    class="bg-white changePasswordForm sm:mt-44 h-fit"
    [formGroup]="changePasswordForm"
    (ngSubmit)="saveChanges()"
  >
    <div
      class="flex flex-col h-fit max-w-md mx-auto pt-12 pl-8 pr-8 bg-white rounded-lg shadow-md gap-5 pb-11"
    >
      <div class="flex text-center justify-center items-center pt-3 pb-3">
        <img
          src="https://d1zl99f7e5jysh.cloudfront.net/image/992382361685/image_segleuhno14kvbimin4o22570c/-FPNG%20-S1000x200"
          width="198px"
          height="46px"
          alt="Fenaco"
        />
      </div>
      <div class="flex text-left">
        <p class="flex text-2xl font-semibold">
          {{ 'changePassword.title' | translate }}
        </p>
      </div>
      <div
        class="mt-2 leading-5 text-base font-light text-[#8E8E8A]"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
      >
        {{ 'changePassword.passwordChangeFeedback' | translate }}
      </div>
      <a
        class="backToLoginLink"
        [ngClass]="{ hidden: step !== 2, flex: step === 2 }"
        (click)="backToLogin()"
      >
        {{ 'changePassword.backToLogin' | translate }}
      </a>
      <div
        class="relative"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <mat-icon
          class="absolute !overflow-visible inset-y-0 left-0 flex items-center pl-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
        >
          lock
        </mat-icon>
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pl-10 pr-14 appearance-none"
          [type]="hideCurrentPassword ? 'password' : 'text'"
          id="currentPassword"
          required="true"
          type="password"
          placeholder="{{ 'changePassword.currentPassword' | translate }}"
          formControlName="currentPassword"
          name="currentPassword"
        />
        <mat-icon
          class="absolute inset-y-0 right-0 flex items-center mr-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
          (click)="hideCurrentPassword = !hideCurrentPassword"
        >
          {{ hideCurrentPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </div>
      <div
        class="relative"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <mat-icon
          class="absolute !overflow-visible inset-y-0 left-0 flex items-center pl-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
        >
          lock
        </mat-icon>
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pl-10 pr-14 appearance-none"
          [type]="hideNewPassword ? 'password' : 'text'"
          id="newPassword"
          required="true"
          type="password"
          (input)="onStrengthChanged(changePasswordForm)"
          (paste)="onPaste($event)"
          placeholder="{{ 'changePassword.newPassword' | translate }}"
          formControlName="newPassword"
          name="newPassword"
        />

        <mat-icon
          class="absolute inset-y-0 right-0 flex items-center mr-3 top-1/2 transform -translate-y-1/2 text-[#8E8E8E]"
          (click)="hideNewPassword = !hideNewPassword"
        >
          {{ hideNewPassword ? 'visibility_off' : 'visibility' }}
        </mat-icon>
      </div>
      <div
        *ngIf="
          changePasswordForm.errors?.mismatch && changePasswordForm.touched
        "
      >
        <p class="text-xs text-[#E35740]">
          {{ 'changePassword.currentPasswordMismatch' | translate }}
        </p>
      </div>

      <!-- <div class="flex flex-col">
        <input
          class="text-left w-full text-sm p-3 text-[#8E8E8E] bg-white border border-[#DDDDDD] focus:outline-none h-50 rounded-sm pr-10 appearance-none"
          id="newPassword"
          required="true"
          type="password"
          placeholder="{{ 'changePassword.newPassword' | translate }}"
          formControlName="newPassword"
          name="newPassword"
        />
      </div> -->

      <div
        class="flex flex-col gap-0.5"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <mat-progress-bar
          mode="determinate"
          [value]="passwordStrength"
          *ngIf="changePasswordForm.get('newPassword').value !== ''"
        ></mat-progress-bar>

        <div class="flex justify-end items-end">
          <p
            class="flex text-xs"
            [ngClass]="passwordStrengthTextColorClass"
            *ngIf="changePasswordForm.get('newPassword').value !== ''"
          >
            {{ passwordStrengthFeedback }}
          </p>
        </div>
        <div class="flex justify-start items-start">
          <ul class="ml-3" style="list-style-type: circle">
            <li class="text-xs text-[#8E8E8A]">
              {{ 'changePassword.passwordStrengthRequirement1' | translate }}
            </li>
            <li class="text-xs text-[#8E8E8A]">
              {{ 'changePassword.passwordStrengthRequirement6' | translate }}
            </li>
            <div class="pl-4">
              <li class="text-xs text-[#8E8E8A]">
                {{ 'changePassword.passwordStrengthRequirement2' | translate }}
              </li>
              <li class="text-xs text-[#8E8E8A]">
                {{ 'changePassword.passwordStrengthRequirement3' | translate }}
              </li>
              <li class="text-xs text-[#8E8E8A]">
                {{ 'changePassword.passwordStrengthRequirement4' | translate }}
              </li>
              <li class="text-xs text-[#8E8E8A]">
                {{ 'changePassword.passwordStrengthRequirement5' | translate }}
              </li>
            </div>
          </ul>
        </div>
      </div>

      <div
        class="flex flex-row gap-2 mt-2 mb-2 leading-5 text-base text-[#8E8E8A]"
        [ngClass]="{ hidden: step !== 1, flex: step === 1 }"
      >
        <button
          class="flex flex-row items-center justify-center w-full h-12 p-1 text-base text-[#86847F] bg-[#fff] rounded-sm border-2 border-[#DDDDDD] disabled:opacity-50"
          (click)="handleBack()"
        >
          {{ 'changePassword.backCta' | translate }}
        </button>
        <button
          style="height: 50px"
          [ngClass]="{
            'bg-[#727573]': isLoading === true,
            'bg-[#019640]': isLoading === false,
            'border-[#009456]': isLoading === false,
            'border-[#727573]': isLoading === true
          }"
          [disabled]="isLoading"
          class="flex flex-row items-center justify-center w-full p-1 text-base text-[#ffffff] rounded-sm border-2 disabled:opacity-50"
          type="submit"
        >
          {{ 'changePassword.cta' | translate }}
        </button>
      </div>
    </div>
  </form>
</div>

import { Component, Input, OnInit, Inject, ChangeDetectorRef } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import {FormBuilder, FormGroup, ValidationErrors, Validators} from '@angular/forms';
import { DataService } from "../../data.service";
import { SnackbarComponent } from '../snackbar/snackbar.component';
import { environment } from '../../../environments/environment';
import {ActivatedRoute, Router} from "@angular/router";
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

type UserProfile = {
  firstName: string;
  lastName: string;
  locale: string;
  salutation: string;
};

@Component({
  selector: 'app-change-password-content',
  templateUrl: './change-password-content.component.html',
  styleUrls: ['./change-password-content.component.css'],
})
export class ChangePasswordContentComponent extends Component implements OnInit {
  @Input() user: User; // The type should match the expected data type
  changePasswordForm: FormGroup; // Define as FormGroup
  passwordStrengthFeedback = "";
  passwordStrengthTextColorClass = ""
  passwordStrength = 0;
  hideCurrentPassword = true;
  hideNewPassword = true;
  step = 1;
  isLoading = false;

  userProfile: UserProfile = null;
  activeMenuItem = 'menuItem2';
  durationInSeconds = 3;
  constructor(
    public translate: TranslateService,
    private _snackBar: MatSnackBar,
    private cd: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private dataService: DataService,
    private route: ActivatedRoute, private router: Router,
    public auth: AuthService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    super({});
    this.translate = translate;
  }

  saveChanges() {
    this.isLoading = true;
    // Handle saving changes
    if(this.passwordStrength < 40) {
      this._snackBar.openFromComponent(SnackbarComponent, {
        duration: 3000,
        verticalPosition: 'top',
        politeness: 'assertive',
        data: { messageTranslationId: 'errors.passwordStrength' },
        panelClass: ['error-snackbar'],
      });
      this.isLoading = false;
      return;
    }
    if (this.changePasswordForm.valid) {
      // Proceed with saving changes
      const formData = this.changePasswordForm.value;
      const data = {
        email: this.user.email,
        newPasswordConfirm: formData.newPassword,
        ...formData,
      }
      this.dataService.postData(environment.changePasswordEndpoint, data).subscribe({
        next: (response) => {
          this.translate.get("changePassword.passwordUpdated").subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['success-snackbar'],
            });
          });
          this.step++;
          this.isLoading = false;
        },
        error: (error) => {
          this.translate.get("errors.passwordChangeError").subscribe((translatedFeedback: string) => {
            this._snackBar.open(translatedFeedback, '✖️', {
              duration: 3000,
              verticalPosition: 'top',
              politeness: 'assertive',
              panelClass: ['error-snackbar'],
            });
          });
          this.isLoading = false;
        }
      });


    } else {
      if (
        this.changePasswordForm.controls.currentPassword.errors?.required
      ) {
        this.translate.get("errors.currentPassword").subscribe((translatedFeedback: string) => {
          this._snackBar.open(translatedFeedback, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
        this.isLoading = false;
        return;
      }
      if (this.changePasswordForm.controls.newPassword.errors?.required) {
        this.translate.get("errors.newPassword").subscribe((translatedFeedback: string) => {
          this._snackBar.open(translatedFeedback, '✖️', {
            duration: 3000,
            verticalPosition: 'top',
            politeness: 'assertive',
            panelClass: ['error-snackbar'],
          });
        });
        this.isLoading = false;
        return;
      }
      this.isLoading = false;
    }
  }

  activeMenu(selectedItem: string) {
    this.activeMenuItem = selectedItem;
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault()
    if (!event.clipboardData) {
      return;
    }
    this.changePasswordForm.get('newPassword')?.setValue(event.clipboardData.getData('text'));
    this.onStrengthChanged(this.changePasswordForm);
    this.cd.detectChanges();
  }

  onStrengthChanged(fg: FormGroup) {
    setTimeout(() => {
      const statusBar = document.querySelector('.mdc-linear-progress__bar-inner');
      if(!statusBar) {
        return;
      }

    const newPassword = fg.get('newPassword')?.value;
    const strength = this.calculateStrength(newPassword);

    if(strength === 1) {
      const translatedFeedback = this.translate.instant('changePassword.passwordStrengthFeedbacks.weak');
      this.passwordStrengthFeedback = translatedFeedback;
      this.passwordStrengthTextColorClass = "text-[#E35740]"
      statusBar.classList.remove('!border-[#E3A140]')
      statusBar.classList.remove('!border-[#145d22]')
      statusBar.classList.remove('!border-[#94e452]')

      statusBar.classList.add('!border-[#E35740]')
      this.passwordStrength = 20;

    } else if(strength === 2) {
      const translatedFeedback = this.translate.instant('changePassword.passwordStrengthFeedbacks.medium');
      this.passwordStrengthFeedback = translatedFeedback;
      this.passwordStrengthTextColorClass = "text-[#E3A140]"
      statusBar.classList.remove('!border-[#E35740]')
      statusBar.classList.remove('!border-[#145d22]')
      statusBar.classList.remove('!border-[#94e452]')
      statusBar.classList.add('!border-[#E3A140]')
      this.passwordStrength = 40;

    } else if(strength === 3) {
      const translatedFeedback = this.translate.instant('changePassword.passwordStrengthFeedbacks.strong');
      this.passwordStrengthFeedback = translatedFeedback;
      this.passwordStrengthTextColorClass = "text-[#94e452]"
      statusBar.classList.remove('!border-[#E35740]')
      statusBar.classList.remove('!border-[#145d22]')
      statusBar.classList.remove('!border-[#E3A140]')
      statusBar.classList.add('!border-[#94e452]')
      this.passwordStrength = 60;


    } else if(strength >= 4) {
      const translatedFeedback = this.translate.instant('changePassword.passwordStrengthFeedbacks.veryStrong');
      this.passwordStrengthFeedback = translatedFeedback;
      this.passwordStrengthTextColorClass = "text-[#145d22]"
      statusBar.classList.remove('!border-[#E35740]')
      statusBar.classList.remove('!border-[#94e452]')
      statusBar.classList.remove('!border-[#E3A140]')
      statusBar.classList.add('!border-[#145d22]')
      this.passwordStrength = 100;
    }
    if(fg.get('newPassword')?.value.length <= 0) {
      this.passwordStrengthFeedback = '';
      statusBar.classList.remove('!border-[#E35740]')
      statusBar.classList.remove('!border-[#94e452]')
      statusBar.classList.remove('!border-[#E3A140]')
      statusBar.classList.remove('!border-[#145d22]')
      this.passwordStrength = 0;
    }
    }, 0);
  }

  ngOnInit() {
    this.userProfile = this.user['https://www.fenaco.com/profile'];

    this.initializeForm(); // Call function to initialize the form
    if(this.userProfile?.locale) {
      this.translate.setDefaultLang(this.userProfile?.locale);
      this.translate.use(this.userProfile?.locale);
    }
  }

  passwordMatchValidator(fg: FormGroup): ValidationErrors | null {
    return fg.get('currentPassword')?.value !== fg.get('newPassword')?.value ? null : { mismatch: true };
  }

  initializeForm() {
    this.changePasswordForm = this.formBuilder.group({
      currentPassword: ['', Validators.required],
      newPassword: ['', Validators.minLength(12)],
    }, { validator: this.passwordMatchValidator });
  }

  calculateStrength(password: string): number {
    let strength = 1;

    if (password.length > 1 && password.length < 12) {

     return strength;
    }

    if (password.length >= 12) {
      strength += 1;
    }

    if (/[a-z]/.test(password) && /[A-Z]/.test(password)) {
      strength += 1;
    }

    if (/\d/.test(password)) {
      strength += 1;
    }

    if (/[@#$%^&*()_+\-={};':"|,.<>?]/.test(password)) {
      strength += 1;
    }
    return strength;
  }

  handleBack() {
    // Handle back button click
    this.router.navigate(['/']);
  }

  backToLogin() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}

<div class="flex h-full flex-col sm:p-0 sm:justify-start bg-[#fff]">
  <div
    id="#top"
    class="flex flex-row sm:h-52 w-full justify-between gap-2 sm:relative top-0 bg-transparent sm:shadow-none"
  >
    <div class="flex flex-col p-4 sm:p-12">
      <img
        src="../../assets/fenaco_LandingPage_Logo.svg"
        width="265px"
        height="110px"
        alt="Fenaco logo"
        class="sm:w-64 w-28"
      />
    </div>
    <div class="flex flex-col p-4 sm:p-12 justify-center">
      <a class="no-underline" [routerLink]="['/']">
        <img
          src="../../assets/landingpage-login.svg"
          width="130px"
          height="52px"
          alt="Login"
          class="sm:w-32 w-20 sm:h-14 h-8"
        />
      </a>
    </div>
  </div>
  <div
    class="flex flex-col w-full mt-12 justify-start pl-8 pr-8 sm:pl-32 sm:mt-0"
  >
    <h2
      class="sm:text-left text-left sm:text-6.2xl text-3xl leading-9 sm:leading-19 landingpageHeader"
    >
    {{ 'landingPage.firstSection.title' | translate }}
    </h2>
  </div>
  <div
    class="flex flex-col w-full justify-center items-center mt-10 pl-8 pr-8 sm:pl-32 sm:pr-32"
  >
    <p
      class="text-center text-xl leading-6 sm:text-3.2xl sm:leading-10.5 landingpageDesc"
    >
      {{ 'landingPage.firstSection.description' | translate }}
    </p>
  </div>

  <div
    class="flex flex-col sm:flex-row w-full justify-between items-center mt-48 pl-8 pr-8 sm:pl-32 sm:pr-32 gap-24"
  >
    <div class="flex flex-col w-full items-start gap-1">
      <mat-icon class="icon-layout">sentiment_satisfied_alt</mat-icon>
      <p class="text-left font-semibold text-xl text-[#009456] w-full">
        {{ 'landingPage.secondSection.benefits.benefit1.title' | translate }}
      </p>
      <p class="feature-text-layout">
        {{ 'landingPage.secondSection.benefits.benefit1.description' | translate }}
      </p>
    </div>
    <div class="flex flex-col w-full items-start gap-1">
      <div
        class="flex flex-col w-12 h-12 bg-[#009456] rounded-full justify-center items-center"
      >
        <mat-icon class="lock-icon">lock</mat-icon>
      </div>
      <p class="text-left font-semibold text-xl text-[#009456] w-full">
        {{ 'landingPage.secondSection.benefits.benefit2.title' | translate }}

      </p>
      <p class="feature-text-layout">
        {{ 'landingPage.secondSection.benefits.benefit2.description' | translate }}

      </p>
    </div>
    <div class="flex flex-col w-full items-left gap-1">
      <mat-icon class="icon-layout">password</mat-icon>

      <p class="text-left font-semibold text-xl text-[#009456] w-full">
        {{ 'landingPage.secondSection.benefits.benefit3.title' | translate }}

      </p>
      <p class="feature-text-layout">
        {{ 'landingPage.secondSection.benefits.benefit3.description' | translate }}

      </p>
    </div>
  </div>
  <div
    class="flex flex-col sm:flex-row w-full justify-between items-center mt-48 p-8 sm:pl-32 sm:pr-32 gap-24 bg-[#FAFAF9]"
  >
    <div class="flex flex-col w-full items-start gap-1">
      <h2
        class="plan-section-title sm:text-3.2xl text-xl leading-9 sm:leading-19"
      >
      {{ 'landingPage.thirdSection.title' | translate }}
      </h2>
      <p
        class="plan-section-subtitle text-xl leading-6 sm:text-3.2xl sm:leading-10.5"
      >
      {{ 'landingPage.thirdSection.description' | translate }}

      </p>
      <p class="plan-section-subtitle mt-20 sm:flex hidden">      {{ 'landingPage.thirdSection.partners' | translate }}      </p>
    </div>
    <div class="flex flex-col w-full h-full items-start gap-1 justify-start">
      <div class="flex flex-col w-full items-center gap-8">
        <div class="flex flex-row w-fit justify-end gap-16">
          <img
            src="../../assets/footer-icon-1.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
          <img
            src="../../assets/footer-icon-2.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
        </div>
        <div class="flex flex-row w-fit justify-end gap-16">
          <img
            src="../../assets/footer-icon-3.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
          <img
            src="../../assets/footer-icon-4.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
        </div>
      </div>
      <p class="plan-section-subtitle mt-20 sm:hidden flex"> {{ 'landingPage.thirdSection.partners' | translate }}</p>
      <div class="flex flex-col w-full items-center gap-8 mt-20">
        <div class="flex flex-row w-fit justify-end gap-16">
          <img
            src="../../assets/landingpage-icon-5.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
          <img
            src="../../assets/landingpage-icon-6.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
        </div>
        <div class="flex flex-row w-fit justify-end gap-16">
          <img
            src="../../assets/landingpage-icon-7.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
          <img
            src="../../assets/landingpage-icon-8.svg"
            width="193px"
            height="77px"
            class="sm:w-48 w-32"
            alt="Fenaco logo"
          />
        </div>
      </div>
    </div>
  </div>
  <div
    class="flex flex-col w-full justify-center items-start sm:mt-48 p-8 sm:pl-32 sm:pr-32 gap-1 bg-[#fff]"
  >
    <h2
      class="plan-section-title sm:text-3.2xl text-xl leading-9 sm:leading-19"
    >
    {{ 'landingPage.forthSection.title' | translate }}
    </h2>
    <p
      class="plan-section-subtitle text-xl leading-6 sm:text-3.2xl sm:leading-10.5"
    >
    {{ 'landingPage.forthSection.description' | translate }}

    </p>
    <button class="readMoreButton w-fit mt-2">    {{ 'landingPage.forthSection.cta' | translate }}    </button>
  </div>
  <div
    class="hidden sm:flex sm:flex-row sm:w-full sm:justify-between sm:items-center sm:mt-48 p-8 sm:pl-32 sm:pr-32 sm:gap-24 sm:bg-[#FAFAF9]"
  >
    <div class="flex flex-col w-full items-start gap-6">
      <img
        src="../../assets/landingpage-footer-logo.svg"
        width="127px"
        height="32px"
        class="sm:w-32 w-24"
        alt="Fenaco logo"
      />

      <div class="flex flex-col sm:flex-row gap-12">
        <p class="font-light text-lg text-[#009456]">{{ 'landingPage.footerDesktop.impressum' | translate }}</p>
        <p class="font-light text-lg text-[#009456]">{{ 'landingPage.footerDesktop.rechtliches' | translate }}</p>
        <p class="font-light text-lg text-[#009456]">{{ 'landingPage.footerDesktop.datenschutz' | translate }}</p>
      </div>
    </div>
    <div
      class="flex flex-row gap-12 w-full h-full sm:justify-start sm:items-center"
    >
      <mat-icon class="icon-layout">language</mat-icon>
      <p (click)="changeColor('de')" [ngClass]="{'font-bold': activeLang === 'de', 'font-normal': activeLang !== 'de'}" class="font-bold text-lg text-[#009456] cursor-pointer">DE</p>
      <p (click)="changeColor('fr')" [ngClass]="{'font-bold': activeLang === 'fr', 'font-normal': activeLang !== 'fr'}" class="font-light text-lg text-[#009456] cursor-pointer">FR</p>
      <p (click)="changeColor('en')" [ngClass]="{'font-bold': activeLang === 'en', 'font-normal': activeLang !== 'en'}" class="font-light text-lg text-[#009456] cursor-pointer">EN</p>
    </div>
  </div>
  <div
    class="flex flex-col sm:hidden w-full justify-between items-center sm:mt-48 p-8 gap-24 bg-[#FAFAF9]"
  >
    <div class="flex flex-col w-full justify-start items-start">
      <img
        src="../../assets/landingpage-footer-logo.svg"
        width="127px"
        height="32px"
        class="sm:w-32 w-24"
        alt="Fenaco logo"
      />
    </div>

    <div class="flex flex-col w-full gap-4">
      <div class="flex flex-col w-full">
        <h2
          class="plan-section-title sm:text-3.2xl text-left text-xl leading-9 sm:leading-19"
        >
        {{ 'landingPage.footerMobile.address' | translate }}
        </h2>
        <p
          class="plan-section-subtitle w-32 text-xl leading-6 sm:text-3.2xl sm:leading-10.5"
        >
          Erlachstrasse 5<br />
          Postfach<br />
          3001 Bern
        </p>
      </div>

      <div class="flex flex-row w-full gap-8">
        <mat-icon class="icon-layout">phone</mat-icon>
        <p
          class="plan-section-title sm:text-3.2xl text-left text-xl leading-9 sm:leading-19"
        >
          +41 31 385 11 11
        </p>
      </div>
      <div class="flex flex-row w-full gap-8">
        <mat-icon class="icon-layout">email</mat-icon>
        <a
          class="plan-section-title sm:text-3.2xl text-left text-xl leading-9 sm:leading-19"
          href="mailto:info@fenaco.com"
        >
          info&#64;fenaco.com
        </a>
      </div>
    </div>

    <div class="flex flex-col w-full justify-start items-start">
      <a href="https://www.fenaco.com/aktuell/newsletter" target="_blank" class="bg-[#009456] w-fit rounded-md mt-2 p-4 text-white">
        {{ 'landingPage.footerMobile.cta' | translate }}
      </a>

      <!-- Scoial Media Icons -->
      <div class="flex flex-row gap-4 mt-4">
        <a href="https://www.facebook.com/fenaco" target="_blank">
          <span class="icon-[mdi--facebook] text-3.2xl text-[#009456]"></span>
        </a>
        <a href="https://www.instagram.com/fenaco/" target="_blank">
          <span class="icon-[mdi--instagram] text-3.2xl text-[#009456]"></span>
        </a>
        <a href="https://twitter.com/fenaco" target="_blank">
          <span class="icon-[mdi--twitter] text-3.2xl text-[#009456]"></span>
        </a>
        <a href="https://www.linkedin.com/company/fenaco/" target="_blank">
          <span class="icon-[mdi--linkedin] text-3.2xl text-[#009456]"></span>
        </a>
        <a href="https://www.youtube.com/user/fenaco" target="_blank">
          <span class="icon-[mdi--youtube] text-3.2xl text-[#009456]"> </span>
        </a>
      </div>
    </div>
    <div class="flex flex-col w-full justify-start items-start gap-4">
      <div class="flex flex-row sm:flex-row gap-5">
        <a href="https://www.fenaco.com/meta/impressum" target="_blank" class="font-light text-lg text-[#009456]">{{ 'landingPage.footerMobile.impressum' | translate }}</a>
        <a href="https://www.fenaco.com/meta/nutzungsbedingungen" target="_blank" class="font-light text-lg text-[#009456]">{{ 'landingPage.footerMobile.rechtliches' | translate }}</a>
        <a href="https://www.fenaco.com/meta/datenschutzhinweis" target="_blank" class="font-light text-lg text-[#009456]">{{ 'landingPage.footerMobile.datenschutz' | translate }}</a>
      </div>

      <div
        class="flex flex-row gap-12 pb-2 items-center w-full h-full sm:justify-start sm:items-center"
      >
        <mat-icon class="icon-layout-mobile">language</mat-icon>
        <button (click)="changeColor('de')"><p [ngClass]="{'font-bold': activeLang === 'de'}" class="text-lg text-[#009456]">DE</p></button>
        <button (click)="changeColor('fr')"><p [ngClass]="{'font-bold': activeLang === 'fr'}" class="font-light text-lg text-[#009456]">FR</p></button>
        <button (click)="changeColor('en')"><p [ngClass]="{'font-bold': activeLang === 'en'}" class="font-light text-lg text-[#009456]">EN</p></button>
      </div>
    </div>
  </div>
  <button (click)="scrollTop()" class="scrollToTop">
    <img
      src="../../assets/top.svg"
      width="50px"
      height="50px"
      alt="Fenaco logo"
    />
  </button>
</div>
